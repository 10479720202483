@use 'assets/styles/variables' as v;
@use '../links.module.scss';

.pseudoLink {
  padding: 0;
  border: none;
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: inherit;
  text-align: left;

  @include links.themes;

  &:focus {
    outline: none;
  }
}

.rightArrow {
  &::after {
    display: inline-block;
    border-width: 4px 0 4px 6px;
    border-style: solid;
    border-color: transparent;
    border-left-color: currentColor;
    margin-left: 0.3em;
    content: ' ';
    vertical-align: middle;
  }
}
