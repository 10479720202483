@use 'assets/styles/media';

.container {
  max-width: 1170px;
  padding-right: 2brm;
  padding-left: 2brm;
  margin-right: auto;
  margin-left: auto;

  &.fluid {
    width: 100%;
  }

  &.withoutPaddings {
    padding: 0;
  }

  @include media.only(xs) {
    padding-right: 1brm;
    padding-left: 1brm;
  }
}

.pageContent {
  margin-top: 4.75brm;

  @include media.only(xs) {
    margin-top: 2brm;
  }
}
