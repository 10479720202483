.osano-cm-window {
  font-family: inherit;
}

.osano-cm-widget {
  display: none;
}

.osano-cm-info {
  border-radius: 1em;
  box-shadow: none;
  background-color: #191a1f;
  color: #7a7c82;

  @media screen and (max-width: xs) {
    max-width: 100%;
  }

  .osano-cm-list:first-of-type .osano-cm-list__list-item {
    border-bottom: none;
  }
}

.osano-cm-list-item__disclosure {
  display: none;
}

.osano-cm-view {
  padding: 0 20px;
}

.osano-cm-description,
.osano-cm-content {
  font-size: 14px;
  line-height: 1.5em;
}

.osano-cm-info-dialog-header {
  background: #191a1f;

  &__close,
  &__close:hover {
    color: #818695;
    stroke: #818695;
  }

  &__header {
    padding: 20px 0 8px 20px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    color: #fff;
    background: #191a1f;
  }
}

.osano-cm-close:focus,
.osano-cm-close:hover {
  transform: none;
}

.osano-cm-close {
  background-color: unset;
  border-color: unset;
  border-width: 0;
  transform: none;
}

.osano-cm-dialog {
  border-radius: 8px;
  padding: 14px 20px 20px;
  background-color: rgba(#191a1f, 0.98);
  color: #c7c7c7;
  font-size: 16px;
  line-height: 24px;
  max-width: 368px;
  transition: none;

  &__list {
    margin-top: 20px;
  }

  &__close {
    display: none;
  }

  @media screen and (max-width: xs) {
    max-width: 100%;
  }
}

.osano-cm-dialog__list .osano-cm-toggle {
  flex-direction: row-reverse;
  justify-content: space-between;

  &__label {
    margin: 0 !important;
  }
}

.osano-cm-storage-policy,
.osano-cm-link {
  align-self: flex-start;
  text-decoration: underline;
  text-underline-offset: 2px;
  font-weight: normal;

  &:focus {
    font-weight: normal;
  }
  &:hover {
    text-decoration: none;
  }
}

.osano-cm-drawer-links {
  display: none;
}

.osano-cm-content__message {
  padding-bottom: 0;

  &:before {
    content: 'Cookie notice · ';
    color: #fff;
    font-weight: 600;
  }
}

.osano-cm-list:first-of-type .osano-cm-list__list-item {
  border-bottom: 1px solid rgba(#818695, 0.25);

  &:last-of-type {
    border-bottom: none;
  }
}

.osano-cm-list {
  &__list-item {
    padding: 8px 0;

    &:last-child {
      border-bottom: none;
    }
  }
}

.osano-cm-usage-list__list {
  margin-top: 8px;
  list-style: none;

  .osano-cm-list-item {
    position: relative;
    padding-left: 16px;

    &:before {
      content: '•';
      position: absolute;
      left: 0;
      transform: translateY(-1px);
    }
  }
}

.osano-cm-drawer-toggle .osano-cm-label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #fff;
}

.osano-cm-buttons {
  margin-top: 18px !important;
  gap: 20px;
}

.osano-cm-button {
  margin: 0;
  border: none;
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 600;
  border-radius: 6px;
  flex: 1 1 auto;
  padding: 8px 22px;

  &--type_manage {
    order: 0;
    color: #fff;
    background: transparent;
    box-shadow: inset 0 0 0 1px currentColor;
    width: 100%;
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase;
    }

    &:hover,
    &:active,
    &:focus {
      background: rgba(#fff, 0.15);
    }
  }

  &--type_save {
    order: 0;
    color: #fff;
    background: transparent;
    box-shadow: inset 0 0 0 1px currentColor;

    &:hover,
    &:active,
    &:focus {
      background: rgba(#fff, 0.15);
    }
  }

  &--type_accept {
    order: 1;
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: rgba(#fff, 0.92);
    }
  }

  &--type_denyAll {
    order: 2;
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: rgba(#fff, 0.92);
    }
  }
}

.osano-cm-toggle {
  &__switch {
    border-radius: 46px;
    width: 40px;
    height: 20px;

    &:before {
      border-width: 0;
    }

    &::after {
      width: 20px;
      height: 20px;
    }
  }
}

.osano-cm-toggle__input:checked + .osano-cm-toggle__switch,
.osano-cm-toggle__input:checked:hover + .osano-cm-toggle__switch,
.osano-cm-toggle__input:checked:focus + .osano-cm-toggle__switch,
.osano-cm-toggle__input:disabled:checked + .osano-cm-toggle__switch,
.osano-cm-toggle__input:disabled:checked:hover + .osano-cm-toggle__switch {
  background-color: #00cc17;
  border-color: #00cc17;
}
