@use 'assets/styles/media';
@use 'assets/styles/colors';

.errorMessageBox {
  color: colors.$gray-2;
}

.button {
  margin: 1brm 0 0;

  @include media.up(md) {
    margin: 0 1brm;
  }
}

.video {
  max-width: 100%;
}

.compact {
  @include media.up(sm) {
    width: 634px;
  }
}
