.react-tel-input {
  position: relative;
  width: 100%;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  disabled: {
    cursor: not-allowed;
  }

  .flag {
    width: 25px;
    height: 20px;
    background-repeat: no-repeat;
  }

  .form-control {
    position: relative;
    z-index: 0;
    width: 300px;
    height: 35px;
    padding-left: 60px;
    border: 1px solid #cacaca;
    border-radius: 5px;
    margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
    margin-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
    margin-left: 0;
    background: #fff;
    font-size: 14px;
    letter-spacing: 0.01rem;
    line-height: 25px;
    outline: none;

    &.invalid-number {
      border: 1px solid #d79f9f;
      border-left-color: #cacaca;
      background-color: #faf0f0;

      &:focus {
        border: 1px solid #d79f9f;
        border-left-color: #cacaca;
        background-color: #faf0f0;
      }
    }

    &.open {
      z-index: 2;
    }
  }

  .selected-flag {
    position: relative;
    width: 49px;
    height: 100%;
    padding: 0 0 0 8px;
    border-radius: 3px 0 0 3px;

    .flag {
      position: absolute;
      top: 50%;
      margin-top: -12px;
    }

    .arrow {
      position: relative;
      top: 50%;
      left: 30px;
      width: 0;
      height: 0;
      border-top: 4px solid #555;
      border-right: 3px solid transparent;
      border-left: 3px solid transparent;
      margin-top: -1px;

      &.up {
        border-top: none;
        border-bottom: 4px solid #555;
      }
    }
  }

  .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    border: 1px solid #cacaca;
    border-radius: 3px 0 0 3px;
    background-color: #f5f5f5;
    outline: none;

    &.open {
      z-index: 2;
      border-radius: 3px 0 0;
      background: #fff;

      .selected-flag {
        border-radius: 3px 0 0;
        background: #fff;
      }
    }

    &:hover,
    &:focus {
      cursor: pointer;

      .selected-flag {
        background-color: #fff;
      }
    }
  }

  input[disabled] {
    & + .flag-dropdown {
      &:hover {
        cursor: default;

        .selected-flag {
          background-color: transparent;
        }
      }
    }
  }

  .country-list {
    position: absolute;
    z-index: 1;
    width: 300px;
    max-height: 224px;
    padding: 0;
    border-radius: 0 0 3px 3px;
    margin: 10px 0 10px -1px;
    background-color: white;
    box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
    list-style: none;
    overflow-y: scroll;

    .flag {
      position: absolute;
      top: 3px;
      left: 10px;
      display: inline-block;
    }

    .divider {
      padding-bottom: 5px;
      border-bottom: 1px solid #ccc;
      margin-bottom: 5px;
    }

    .country {
      position: relative;
      padding: 7px 9px 7px 44px;

      .dial-code {
        color: #6b6b6b;
      }

      &:hover {
        background-color: #f1f1f1;
      }

      &.highlight {
        background-color: #f1f1f1;
      }
    }

    .country-name {
      margin-right: 6px;
    }

    .search {
      position: sticky;
      z-index: 2;
      top: 0;
      padding: 10px 0 6px 10px;
      background-color: #fff;
    }

    .search-emoji {
      font-size: 15px;
    }

    .search-box {
      padding: 3px 8px 5px;
      border: 1px solid #cacaca;
      border-radius: 3px;
      margin-left: 6px;
      font-size: 15px;
      line-height: 15px;
      outline: none;
    }

    .no-entries-message {
      padding: 7px 10px 11px;
      opacity: 0.7;
    }
  }
}
