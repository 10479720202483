@use 'assets/styles/spacing';
@use 'assets/styles/media';
@use 'assets/styles/media_style_generator' as msg;

@each $size in msg.$MEDIA_SIZES_REVERSE {
  @include media.only($size) {
    .#{msg.nameSelector(hidden, $size)} {
      display: none;
    }
  }
}

@each $size in msg.$MEDIA_SIZES {
  @include msg.mediaSwitcher($size) {
    @each $val in (center, left, right) {
      .#{msg.nameSelector($val, $size)} {
        text-align: $val;
      }
    }
  }
}
