@use './reset.scss';
@use './fonts.scss';
@use './colors';
@use './variables' as v;
@use './osano';

/* The following block can be included in a custom.scss */

* {
  box-sizing: border-box;
  -webkit-font-feature-settings: 'cv11';
  -moz-font-feature-settings: 'cv11';
  -o-font-feature-settings: 'cv11';
  font-feature-settings: 'cv11';
}

html {
  line-height: 1;
}

body {
  padding: 0;
  margin: 0;
  background-color: colors.$white;
  color: colors.$black-80;
  font-family: v.$font-family-base;
  font-size: v.$font-size-m;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* below are the rules for sticky nav support + avoid horizontal scroll */
  overflow: hidden;
  overflow-y: scroll;
  height: 100vh;
}

html.lenis body {
  height: auto;
  overflow: visible;
  overflow-y: unset;
}

body.noScroll {
  position: relative;
  /* stylelint-disable-next-line declaration-no-important */
  overflow: hidden !important;
}

/* stylelint-disable-next-line selector-class-pattern */
.grecaptcha-badge {
  visibility: hidden;
}

#cognito-flow--root > div {
  z-index: v.$modal-overlay-z-index;
  background-color: rgba(0, 0, 0, 0.48);
}
