/* Inter static font*/

@font-face {
  font-display: swap;
  src: url('../fonts/inter-extra-light.woff2') format('woff2');
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-display: swap;
  src: url('../fonts/inter-light.woff2') format('woff2');
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-display: swap;
  src: url('../fonts/inter-medium.woff2') format('woff2');
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-display: swap;
  src: url('../fonts/inter-medium.woff2') format('woff2');
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-display: swap;
  src: url('../fonts/inter-semi-bold.woff2') format('woff2');
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-display: swap;
  src: url('../fonts/inter-bold.woff2') format('woff2');
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-display: swap;
  src: url('../fonts/inter-extra-bold.woff2') format('woff2');
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-display: swap;
  src: url('../fonts/inter-black.woff2') format('woff2');
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
}

/* Inter variable font*/

@font-face {
  font-display: swap;
  src: url('../fonts/inter.var.woff2') format('woff2');
  font-family: 'Inter var';
  font-style: normal;
  font-weight: 100 900;
}
