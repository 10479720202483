@import 'assets/styles/colors';
@import 'assets/styles/variables';
@import 'assets/styles/media';
@import 'froala-editor/css/froala_style.min.css';
@import 'froala-editor/css/froala_editor.pkgd.min.css';

#fr-logo {
  display: none;
}

.fr-logo {
  display: none;
}

.fr-box {
  position: relative;
}

.fr-wrapper {
  overflow: visible;
  border: 1px solid $gray-e0;
  border-radius: $border-radius-large;
  background-color: white;
}

/* stylelint-disable-next-line */
#linkList-3 {
  display: none;
}

.fr-element {
  padding: 11px 10px;
  color: $gray-2;
}

.fr-counter {
  position: relative;

  &::after {
    position: absolute;
    top: 50%;
    left: 0;
    width: 86px;
    height: 21px;
    background: white;
    content: '';
    transform: translateY(-50%);
  }
}

.fr-view,
.fr-result {
  color: $gray-2;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 24px; /* 150% */

  h1 {
    font-size: 68px;
    font-style: normal;
    font-weight: 900;
    letter-spacing: -2.72px;
    line-height: 64px; /* 94.118% */
  }

  h2 {
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    letter-spacing: -1.44px;
    line-height: 48px; /* 100% */
  }

  h3 {
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    letter-spacing: -1.08px;
    line-height: 40px; /* 111.111% */
  }

  h4 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.48px;
    line-height: 30px; /* 125% */
  }

  h5 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.36px;
    line-height: 26px; /* 144.444% */
  }

  ul {
    li {
      list-style: disc;
    }
  }

  ol {
    li {
      list-style: decimal;
    }
  }

  ol,
  ul {
    padding-left: 30px;
  }

  a {
    color: $brand-primary;
    text-decoration: underline;
  }
}
