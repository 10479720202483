@use 'assets/styles/media_style_generator' as generator;
@use 'assets/styles/media';

.flex {
  display: flex;
}

@each $size in generator.$MEDIA_SIZES {
  @include generator.mediaSwitcher($size) {
    .#{generator.nameSelector(hidden-true, $size)} {
      display: none;
    }
    .#{generator.nameSelector(hidden-false, $size)} {
      display: flex;
    }
  }
}

$flex-directions: row, column, row-reverse, column-reverse;
$flex-wraps: nowrap, wrap, wrap-reverse;
$flex-justify-content: normal, flex-start, flex-end, center, space-between,
  space-around;
$flex-align-items: normal, flex-start, flex-end, center, stretch, baseline;
$flex-align-content: normal, flex-start, flex-end, center, stretch,
  space-between, space-around;

@include generator.generateStyleVariations($flex-directions, 'flex-direction');
@include generator.generateStyleVariations($flex-wraps, 'flex-wrap');
@include generator.generateStyleVariations(
  $flex-justify-content,
  'justify-content'
);
@include generator.generateStyleVariations($flex-align-items, 'align-items');
@include generator.generateStyleVariations(
  $flex-align-content,
  'align-content'
);
@include generator.generateStyleWithCssVar('gap');
