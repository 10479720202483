@use 'sass:color';
@use 'assets/styles/variables' as v;
@use 'assets/styles/media';
@use 'assets/styles/colors';

$selectors-disabled: '&[disabled], &[disabled=' disabled
  '], &[disabled="true"], &.disabled';
$selectors-active: '&:active, &:active:hover';

.main.main {
  display: inline-block;
  height: initial;
  padding: 10px v.$sp-24 11px;
  border: none;
  border-radius: v.$input-border-radius-small;
  cursor: pointer;
  font-size: v.$button-font-size;
  font-weight: 600;
  text-align: center;
  text-decoration: none;

  &.forIcon :global([class*='icon-']) {
    margin: 0;
  }

  &.small {
    padding: 6px v.$sp-16 8px;
    border-radius: v.$border-radius-base;
    font-size: v.$input-fontsize-small;

    &.small.forIcon {
      padding: 2px 10px v.$sp-4;
      line-height: 1.5;
    }
  }

  &.tiny {
    padding: 3px 10px;
    border-radius: v.$border-radius-base;
    font-size: 11px;
    letter-spacing: 0.39px;
  }

  &.extraLarge {
    padding: v.$sp-12 v.$sp-32 14px;
    border-radius: v.$border-radius-base;
    font-size: v.$input-fontsize-large;
  }

  &.loading {
    color: transparent;
  }

  #{$selectors-disabled} {
    cursor: not-allowed;

    &,
    &:hover {
      text-shadow: none;
    }
  }

  &.loading.loading {
    position: relative;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSIjZjBmMGYwIj4KICA8cGF0aCBvcGFjaXR5PSIuMjUiIGQ9Ik0xNiAwIEExNiAxNiAwIDAgMCAxNiAzMiBBMTYgMTYgMCAwIDAgMTYgMCBNMTYgNCBBMTIgMTIgMCAwIDEgMTYgMjggQTEyIDEyIDAgMCAxIDE2IDQiLz4KICA8cGF0aCBkPSJNMTYgMCBBMTYgMTYgMCAwIDEgMzIgMTYgTDI4IDE2IEExMiAxMiAwIDAgMCAxNiA0eiI+CiAgICA8YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgZnJvbT0iMCAxNiAxNiIgdG89IjM2MCAxNiAxNiIgZHVyPSIwLjhzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L3BhdGg+Cjwvc3ZnPgo=');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 40%;
    color: transparent;
    pointer-events: none;
    text-shadow: none;

    &::before {
      opacity: 0;
    }
  }

  &.primary {
    background-color: colors.$brand-primary;
    color: colors.$white;
    #{$selectors-active},
    &:hover {
      background: #2d69ff;
    }
    #{$selectors-disabled},
    &.loading {
      background-color: rgba((colors.$brand-primary), 0.5);
    }

    #{$selectors-disabled} {
      color: colors.$white;
    }
  }

  &.shapeCircle {
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 50%;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
  }

  &.fullWidth {
    width: 100%;
  }

  &.fullHeight {
    height: 100%;
  }

  &.shapeRound {
    // ensures pill shape regardless of size
    border-radius: 99em;
  }

  &.withIcon {
    display: flex;
    align-items: center;

    & .icon:not(:only-child) {
      margin-right: v.$sp-12;
    }
  }

  &.primary.danger {
    background-color: colors.$danger;

    &:hover,
    &:focus {
      background: color.adjust(colors.$danger, $lightness: 8%);
    }
    #{$selectors-active} {
      background: color.adjust(colors.$danger, $lightness: -8%);
    }
    #{$selectors-disabled},
    &.loading {
      background-color: rgba((colors.$danger), 0.5);
    }
  }

  &.primary.success {
    background-color: colors.$success;

    #{$selectors-disabled},
    &.loading {
      background-color: rgba((colors.$success), 0.5);
    }
  }

  &.default {
    background-color: colors.$white;
    box-shadow: inset 0 0 0 1px colors.$gray-20;
    color: colors.$black-80;

    &:hover {
      border-color: color.adjust(v.$input-border-color, $lightness: -10%);
      box-shadow: inset 0 0 0 1px colors.$republic-blue;
      color: colors.$republic-blue;
    }

    #{$selectors-disabled} {
      background-color: colors.$white;
      box-shadow: inset 0 0 0 1px color.adjust(colors.$white, $lightness: -12%);
      color: rgba((colors.$black-80), 0.3);
    }
  }

  &.defaultThin {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px rgba((colors.$black), 0.3);
    color: colors.$black-80;
    font-size: v.$font-size-s;
    transition-duration: 0.1s;
    transition-property: background-color, border-color, box-shadow, color,
      opacity;
    transition-timing-function: ease-in-out;

    &:hover {
      background-color: rgba((colors.$black), 0.05);
      box-shadow: inset 0 0 0 1px rgba((colors.$black), 0.5);
    }

    #{$selectors-disabled} {
      background-color: colors.$white;
      box-shadow: inset 0 0 0 1px color.adjust(colors.$white, $lightness: -12%);
      color: rgba((colors.$black-80), 0.3);
    }
  }

  &.shadow {
    box-shadow: 0 4px 4px rgba((colors.$black), 25%);
  }

  &.shadowInset {
    box-shadow: inset 0 0 0 1px colors.$gray-5;
  }

  &.noShadow {
    box-shadow: none;
  }

  &.ghost {
    background-color: colors.$white;
    color: color.adjust(colors.$brand-primary, $lightness: -25%);

    &::before,
    &:hover,
    &:focus,
    #{$selectors-active} {
      color: color.adjust(colors.$brand-primary, $lightness: -25%);
    }

    &:hover,
    &:focus,
    #{$selectors-active} {
      background-color: rgba((colors.$white), 0.9);
    }

    #{$selectors-active} {
      background-color: rgba((colors.$white), 0.8);
    }

    #{$selectors-disabled} {
      color: rgba(color.adjust(colors.$brand-primary, $lightness: -40%), 0.6);
    }

    &.loading,
    #{$selectors-disabled} {
      background-color: rgba((colors.$white), 0.4);
    }
  }

  // Only used in the header
  &.grayBackgroundLink {
    padding: 3.5px 16px 5px;
    border: none;
    background-color: rgba((colors.$black), 0.06);
    color: colors.$gray-2;
    font-weight: 400;

    &:hover {
      color: colors.$brand-primary;
    }
  }

  &.link {
    padding: 0 0 2px;
    border-radius: 0;
    background-color: transparent;
    color: colors.$brand-primary;
    font-size: inherit;
    font-weight: normal;

    &.underline {
      border-bottom: 1px solid currentColor;
    }

    &.gray60 {
      color: colors.$gray-60;

      &.underline {
        border-color: colors.$gray-60;

        &:hover {
          border-bottom: 1px solid colors.$brand-primary;
        }
      }
    }

    &:hover {
      color: colors.$brand-primary;
    }
  }

  &.borderedBlue {
    background-color: colors.$white;
    box-shadow: inset 0 0 0 2px colors.$brand-primary;
    color: colors.$brand-primary;

    &:hover,
    &:focus {
      box-shadow: inset 0 0 0 2px colors.$republic-light-blue-hover;
      color: colors.$republic-light-blue-hover;
    }

    #{$selectors-disabled} {
      box-shadow: inset 0 0 0 2px colors.$gray-40;
      color: colors.$gray-40;
    }
  }

  &.wideBorder span {
    padding: 0 v.$sp-32;
  }

  &.wide {
    padding-right: 3.5em;
    padding-left: 3.5em;

    &.responsive {
      @include media.only(xs) {
        padding-right: initial;
        padding-left: initial;
      }
    }
  }

  &.responsive {
    @include media.only(xs) {
      width: 100%;
    }
  }

  &.squareCornersLeft {
    border-radius: 0 v.$input-border-radius-base v.$input-border-radius-base 0;
  }

  &.squareCornersRight {
    border-radius: v.$input-border-radius-base 0 0 v.$input-border-radius-base;
  }
}

.anchor {
  line-height: normal;
}
