@use 'assets/styles/variables' as v;
@use 'assets/styles/media';
@use 'assets/styles/media_style_generator' as msg;
@use 'assets/styles/spacing' as s;

.text {
  font-family: v.$font-family-base;
  font-weight: normal;
}

@include msg.declare(h1N) {
  composes: text;
  font-size: v.$font-size-h1;
  font-weight: 900;
  letter-spacing: -2.6px;
  line-height: 1;
}

.h1 {
  composes: h1N;

  @include media.only(xs) {
    font-size: v.$font-size-h2;
  }
}

@include msg.declare(h2N) {
  composes: text;
  font-size: v.$font-size-h2;
  font-style: normal;
  font-weight: 900;
  letter-spacing: -1.7px;
  line-height: 1;
}

.h2 {
  /* primary */
  composes: h2N;

  &.small {
    font-size: v.$font-size-h3;
  }

  @include media.only(xs) {
    font-size: v.$font-size-h3;

    &.fixed,
    &Mobile {
      font-size: v.$font-size-h2;
    }
  }
}

@include msg.declare(h3N) {
  composes: text;
  font-size: v.$font-size-h3;
  font-weight: 900;
  letter-spacing: -1px;
  line-height: 1.06em;
}

.h3 {
  composes: h3N;

  @include media.only(xs) {
    font-size: v.$font-size-h4;

    &Mobile {
      font-size: v.$font-size-h3;
    }
  }
}

@include msg.declare(h4N) {
  /* secondary */
  composes: text;
  font-size: v.$font-size-h4;
  font-style: normal;
  font-weight: 800;
  letter-spacing: -0.7px;
  line-height: 28px;
}

.h4 {
  composes: h4N;

  &.small {
    font-size: v.$font-size-h5;
  }

  @include media.only(xs) {
    font-size: v.$font-size-h5;

    &.fixed,
    &Mobile {
      font-size: v.$font-size-h4;
    }
  }
}

@include msg.declare(h5N) {
  composes: text;
  font-size: v.$font-size-h5;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.14px;
  line-height: 23px;
}

.h5 {
  composes: h5N;

  @include media.only(xs) {
    font-size: v.$font-size-h6;

    &Mobile {
      font-size: v.$font-size-h6;
    }
  }
}

@include msg.declare(bodyBigN) {
  composes: text;
  font-size: v.$font-size-l;
  letter-spacing: 0;
  line-height: 25px;
}

.bodyBig {
  composes: bodyBigN;

  @include media.only(xs) {
    font-size: v.$font-size-s;

    &Mobile {
      font-size: v.$font-size-l;
    }
  }
}

@include msg.declare(bodyMediumN) {
  composes: text;
  font-size: v.$font-size-m;
  letter-spacing: 0;
  line-height: 24px;
}

.bodyMedium {
  composes: bodyMediumN;

  &.light {
    line-height: 23px;
  }

  @include media.only(xs) {
    &Mobile {
      font-size: v.$font-size-m;
    }
  }
}

@include msg.declare(bodySmallN) {
  composes: text;
  font-size: v.$font-size-s;
  letter-spacing: 0;
  line-height: 1.2;
}

.bodySmall {
  composes: bodySmallN;

  @include media.only(xs) {
    &Mobile {
      font-size: v.$font-size-s;
    }
  }
}

@each $size in msg.$MEDIA_SIZES {
  @include msg.mediaSwitcher($size) {
    @each $val in (center, left, right) {
      .#{msg.nameSelector($val, $size)} {
        text-align: $val;
      }
    }

    @each $val in (300, 400, 500, 600, 800, 900) {
      .#{msg.nameSelector(weight#{$val}, $size)} {
        font-weight: $val;
      }
    }

    @each $name, $val in (0_9: 0.9, 1: 1, 1_2: 1.2, 1_3: 1.3, 1_4: 1.4) {
      .#{msg.nameSelector(lineHeight#{$name}, $size)} {
        line-height: $val;
      }
    }

    @each $name, $val in (light: 300, bold: 600) {
      .#{msg.nameSelector($name, $size)} {
        font-style: normal;
        font-weight: $val;
      }
    }

    .#{msg.nameSelector(underline, $size)} {
      text-decoration: underline;
    }

    .#{msg.nameSelector(italic, $size)} {
      font-style: italic;
    }
  }
}
